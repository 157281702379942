<template>
    <b-modal
        id="confirm-modal-success"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
        centered
        v-model="formContext.showScheduleModal"
    >
        <h2>Cronograma da Campanha</h2>
        <h4 class="pb-2 text-secondary">{{ context.descriptionCampaignSelected }}</h4>
        <div class="d-flex align-items-center mb-1">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                Adesão é feita pela 
                <label class="text-custom-blue m-0 font-14">Empresa</label> 
                ou pelo 
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>
        <div class="d-flex align-items-center mb-1">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                Agendamento das aplicações é feito pela
                <label class="text-custom-blue m-0 font-14">Empresa</label> 
                ou pelo 
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>
        <div class="d-flex align-items-center mb-2">
            <feather-icon icon="CheckIcon" size="20" class="text-custom-blue mr-1"/>
            <p class="font-14 m-0">
                O ato vacinal é feito pelo
                <label class="text-custom-blue m-0 font-14">SESI</label>
            </p>
        </div>

        <b-alert variant="danger" :show="formContext.form.errorApi.error" class="mb-0">
            <div class="alert-body">
                <feather-icon icon="InfoIcon" class="mr-50"/>
                {{ formContext.form.errorApi.error }}
            </div>
        </b-alert>

        <div v-if="formContext.loadingSchedule" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom-blue" class="mb-1" label="Loading..."/>
        </div>

        <validation-observer v-else ref="lancamentoRules">
            <b-form>
                <h5>Adesão</h5>
                <b-row>
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-date-adesao">Data de início*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="startDateAdesao"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="start-date-adesao"
                            :hide-header="true"
                            v-model="formContext.form.startDateAdesao"
                            placeholder="00/00/0000"
                            :disabled="formContext.disabledFieldEdit.adhesionStartDate"
                            right
                            label-help="Selecione a data de início da adesão"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.data_inicio" class="text-danger">
                            {{ formContext.form.errorApi.adesao.data_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="end-date-adesao">Data de término*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="endDateAdesao"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="end-date-adesao"
                            :hide-header="true"
                            v-model="formContext.form.endDateAdesao"
                            placeholder="00/00/0000"
                            :disabled="formContext.disabledFieldEdit.adhesionEndDate"
                            right
                            label-help="Selecione a data de término da adesão"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.data_termino" class="text-danger">
                            {{ formContext.form.errorApi.adesao.data_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mt-1 mb-2">
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-hour-adesao">Horario de início*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="startTimeAdesao"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.startTimeAdesao' 
                            :disabled="formContext.disabledFieldEdit.adhesionStartDate"
                            locale='pt-BR' 
                            no-close-button
                            placeholder="08:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.hora_inicio" class="text-danger">
                            {{ formContext.form.errorApi.adesao.hora_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="start-hour-adesao">Horario de término*</label>
                        <validation-provider
                            #default="{ errors }"
                            name="endtimeAdesao"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.endTimeAdesao' 
                            :disabled="formContext.disabledFieldEdit.adhesionEndDate"
                            locale="pt-BR" 
                            no-close-button
                            placeholder="18:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.adesao.hora_termino" class="text-danger">
                            {{ formContext.form.errorApi.adesao.hora_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <h5>Vacinação</h5>
                <b-row>
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-date-vacinacao">Data de início*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="startDateVacina"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="start-date-vacinacao"
                            :hide-header="true"
                            v-model="formContext.form.startDateVacinacao"
                            :disabled="formContext.disabledFieldEdit.vaccinationStartDate"
                            placeholder="00/00/0000"
                            right
                            label-help="Selecione a data de início da vacinação"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.data_inicio" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.data_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="end-date-vacinacao">Data de término*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="endDateVacina"
                            rules="required"
                        >
                        <b-form-datepicker
                            id="end-date-vacinacao"
                            :hide-header="true"
                            v-model="formContext.form.endDateVacinacao"
                            placeholder="00/00/0000"
                            right
                            label-help="Selecione a data de término da vacinação"
                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                            locale="pt-BR"
                        />
                        <small v-if="errors[0]" class="text-danger">
                        Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.data_termino" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.data_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col lg="6" md="6" sm="12">
                        <label for="start-hour-vacinacao">Horario de início*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="startTimeVacina"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.startTimeVacinacao' 
                            :disabled="formContext.disabledFieldEdit.vaccinationStartDate"
                            locale='pt-BR' 
                            no-close-button
                            placeholder="08:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.hora_inicio" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.hora_inicio }}
                        </small>
                        </validation-provider>
                    </b-col>
                    <b-col lg="6" md="6" sm="12" class="pl-0">
                        <label for="start-hour-vacinacao">Horario de término*</label>
                         <validation-provider
                            #default="{ errors }"
                            name="endTimeVacina"
                            rules="required"
                        >
                        <b-form-timepicker 
                            :hide-header="true"
                            v-model='formContext.form.endTimeVacinacao' 
                            locale='pt-BR' 
                            no-close-button
                            placeholder="18:00"
                        />
                        <small v-if="errors[0]" class="text-danger">
                            Este campo é de preenchimento obrigatório.
                        </small>
                        <small v-if="formContext.form.errorApi.vacinacao.hora_termino" class="text-danger">
                            {{ formContext.form.errorApi.vacinacao.hora_termino }}
                        </small>
                        </validation-provider>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <div class="errorsForm mt-1">
            <small v-if="formContext.errorSchedule" class="text-danger">
                {{ formContext.errorSchedule }}
            </small>
        </div>
        <div class="buttons mt-4 mb-1 d-flex justify-content-center">
            <b-button class="mr-3" id="campaign-schedule" 
                :disabled="formContext.savingSchedule"
                variant="custom-blue"
                @click="validationForm"
            >
                <span>{{ edit ? 'Salvar Cronograma' : 'Lançar Campanha' }}</span>
                <span v-if="formContext.savingSchedule" class="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </b-button>

            <b-button v-if="!edit" class="" id="campaign-schedule" 
                variant="outline-dark"
                @click="formContext.cancelScheduleCampaign"
            >Voltar</b-button>

            <b-button v-else id="cancel-schedule-campaign" 
                variant="outline-danger"
                @click.prevent="formContext.cancelScheduleCampaign"
            >
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">Cancelar</span>
            </b-button>
        </div>
    </b-modal>
</template>

<script>
import { BModal, BButton, BRow, BCol, BForm, BSpinner, BAlert,
BFormDatepicker, BInputGroupAppend, BFormTimepicker } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

export default {
    components: {
        BModal, BButton, BRow, BCol, BForm, BSpinner, BAlert,
        ValidationProvider, ValidationObserver,
        BFormDatepicker, BInputGroupAppend, BFormTimepicker
    },

    props: ['formContext', 'edit'],

    data() {
        return {
            required,
            context: this.$props.formContext,
        }
    },

    methods: {
        validationForm() {
            this.$refs.lancamentoRules.validate().then(success => {
                if (success) {
                    this.formContext.saveScheduledCampaign();
                }
            })
        },
    }
}
</script>

<style>
#confirm-modal-success___BV_modal_header_ {
    background-color: white;
    padding-bottom: 0;
}

.font-14 {
    font-size: 1rem;
}
button.close {
    display: none;
}
</style>