import moment from 'moment';
import 'moment-timezone';

export default {
    prepareDataSchedule(formSchedule, situation = '') {
        const adesaoStartDate = moment.tz(new Date(formSchedule.startDateAdesao+' '+formSchedule.startTimeAdesao), 'America/Sao_Paulo');
        const adesaoFinishDate = moment.tz(new Date(formSchedule.endDateAdesao+' '+formSchedule.endTimeAdesao), 'America/Sao_Paulo');

        const vacinacaoStartDate = moment.tz(new Date(formSchedule.startDateVacinacao+' '+formSchedule.startTimeVacinacao), 'America/Sao_Paulo');
        const vacinacaoFinishDate = moment.tz(new Date(formSchedule.endDateVacinacao+' '+formSchedule.endTimeVacinacao), 'America/Sao_Paulo');

        return {
            adesao: {
                data_inicio: adesaoStartDate.format('YYYY-MM-DD'),
                hora_inicio: adesaoStartDate.format('HH:mm:ss'),
                data_termino: adesaoFinishDate.format('YYYY-MM-DD'),
                hora_termino: adesaoFinishDate.format('HH:mm:ss'),
            },
            vacinacao: {
                data_inicio: vacinacaoStartDate.format('YYYY-MM-DD'),
                hora_inicio: vacinacaoStartDate.format('HH:mm:ss'),
                data_termino: vacinacaoFinishDate.format('YYYY-MM-DD'),
                hora_termino: vacinacaoFinishDate.format('HH:mm:ss'),
            },
            situacao: situation
        }
    }
}
