export default {
    handleErrosLancamento(errorData, errorForm) {
        let error = errorData.errors;

        errorForm.adesao.data_inicio = error['adesao.data_inicio'] ? error['adesao.data_inicio'][0] : '';
        errorForm.adesao.hora_inicio = error['adesao.hora_inicio'] ? error['adesao.hora_inicio'][0] : '';
        errorForm.adesao.data_termino = error['adesao.data_termino'] ? error['adesao.data_termino'][0] : '';
        errorForm.adesao.hora_termino = error['adesao.hora_termino'] ? error['adesao.hora_termino'][0] : '';
        errorForm.vacinacao.data_inicio = error['vacinacao.data_inicio'] ? error['vacinacao.data_inicio'][0] : '';
        errorForm.vacinacao.hora_inicio = error['vacinacao.hora_inicio'] ? error['vacinacao.hora_inicio'][0] : '';
        errorForm.vacinacao.data_termino = error['vacinacao.data_termino'] ? error['vacinacao.data_termino'][0] : '';
        errorForm.vacinacao.hora_termino = error['vacinacao.hora_termino'] ? error['vacinacao.hora_termino'][0] : '';

        errorForm.error = null;

        if(typeof error == 'string') {
            errorForm.error = error;
        }
    },

    cleanErrors(errorForm) {
        errorForm.adesao.data_inicio = '';
        errorForm.adesao.hora_inicio = '';
        errorForm.adesao.data_termino = '';
        errorForm.adesao.hora_termino = '';
        errorForm.vacinacao.data_inicio = '';
        errorForm.vacinacao.hora_inicio = '';
        errorForm.vacinacao.data_termino = '';
        errorForm.vacinacao.hora_termino = '';
    }
}